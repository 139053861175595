import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import LoginBanner from '../components/common/LoginBanner'
import { unauthNavTo } from '../components/navigation'
import Spinner from '../components/spinner'
// import { KEY_AUTHENTICATED, useLoginMutation } from '../components/api'
import { KEY_AUTHENTICATED, useLoginMutation } from '../utils/api'

const OuterContainer = styled.div`
@media only screen and (max-width: 768px) {
  min-height: 500px;
}
@media only screen and (min-width: 767px) {
  height: 500px;
}
`

// markup
const IndexPage = (props) => {
  // state
  const [credentials, setCredentials] = React.useState({ username: '', password: '' })
  const [errorMessage, setErrorMessage] = React.useState(null)

  const queryClient = useQueryClient()
  const loginMutation = useLoginMutation()

  // onload...
  React.useEffect(() => {
    console.debug('Clearing AUTHENTICATED state... - ' + KEY_AUTHENTICATED())
  }, [queryClient])

  React.useEffect(() => {
    if (loginMutation.isError) {
      const { response } = loginMutation.error
      if (response) {
        setErrorMessage(response.data.message)
      } else {
        setErrorMessage('Invalid username or password.')
      }
    }
  }, [loginMutation.error, loginMutation.isError])

  // trigger login
  const doLogin = (e) => {
    e.preventDefault()
    loginMutation.mutate(credentials)
  }

  return (
    <main className="main flex-fill" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner branding={props.branding} />
                  <div className="col-md-7 d-flex flex-center">
                    <div
                      className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5"
                      style={{ minHeight: '300px' }}
                    >
                      <div className="d-flex justify-content-between">
                        <h3 className="mb-3 font-weight-bold pr-3">Login</h3>
                        <span
                          className="my-2 font-weight-bold  align-baseline"
                          style={{ fontSize: 14 + 'px' }}
                        >
                          Or{' '}
                          <a
                            className="link "
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              unauthNavTo.registerAccountWithToken()
                            }}
                          >
                            {' '}
                            register for an account
                          </a>
                        </span>
                      </div>
                      {/* form */}
                      <Form className="d-flex flex-column flex-grow-1 justify-content-between">

                        <div>
                          <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="email"
                              // placeholder="Email address"
                              autoComplete="username"
                              autoFocus={true}
                              value={credentials.username}
                              onChange={e => setCredentials(ps => ({ ...ps, username: e.target.value }))}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              // placeholder="Password"
                              autoComplete="current-password"
                              value={credentials.password}
                              onChange={e => setCredentials(ps => ({ ...ps, password: e.target.value }))}
                            />
                            <div className="row justify-content-end pt-1 px-3 align-items-center">
                              <a className="link cursor-pointer" onClick={() => unauthNavTo.forgotPassword({ emailAddress: credentials.username })}>Forgot Password?</a>
                            </div>
                          </Form.Group>

                        </div>

                        <Form.Group className="mb-0">
                          <Button
                            className="btn-block"
                            type="submit"
                            disabled={loginMutation.isLoading || credentials.username.length === 0 || credentials.password.length === 0}
                            onClick={(e) => doLogin(e)}
                          >
                            {loginMutation.isLoading ? <Spinner /> : <span>Login</span>}
                          </Button>
                        </Form.Group>

                        {loginMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{errorMessage}</Alert>}

                      </Form>

                    </div>
                  </div>
                </div>
              </div>
            </OuterContainer>

            {process.env.NODE_ENV !== 'production' && <pre>credentials={JSON.stringify(credentials, null, 2)}</pre>}
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
